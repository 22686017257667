.ui-tooltip {
  pointer-events: none;
}

.p-icon-secondary {
  color: blue;
  font-weight: bolder;
}
.p-icon-warning {
  color: rgb(255, 180, 0);
  font-weight: bolder;
}
.p-icon-success {
  color: green;
  font-weight: bolder;
}
.p-icon-danger {
  color: red;
  font-weight: bolder;
}

.p-icon-info {
  color: gray;
  font-weight: bolder;
}

.p-loginGroup {
  margin: 0 auto;
  width: "50%";
}

.dashboard_iconbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard_icon {
  font-size: "3em" !important;
}
.checkbox_ml {
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox_ml_label {
  margin-left: 5px;
}

.layout-h1 {
  width: 100px;
  font-size: 18px;
  margin-left: 10px;
  font-weight: bold;
  font-style: italic;
  color: midnightblue;
}
.hidden {
  display: none;
}

.div-NoOverflow {
  height: inherit;
  overflow: hidden;
}
.p-error {
  color: red;
}
.p-invalid {
  border-color: red;
}
.p-field {
  margin-top: 10px;
}
.p-formField {
  margin-top: 24px;
}
.p-left {
  text-align: left;
}

// .p-tableColumnRight {
//   text-align: right;
// }

// .td.tableColumnRight {
//   text-align: center;
// }

// .p-noborder .p-inputnumber-input{
//     border : 0px !important;
//     font-weight: bold;
// }
.p-tableHeader {
  font-weight: normal;
  padding: 0.1em;
  font-size: 24px;
}
.p-table-actionbuttonset {
  display: block;
  float: right;
  // margin-top: -10px
}
.p-tableFooter .p-inputnumber-input {
  border: 0px !important;
  font-weight: bold;
}
.p-inputnumber-input {
  text-align: right;
}
.p-inputgroup_custom {
  color: black;
}
.p-dropdown {
  width: inherit;
}
.p-actionbuttonset {
  display: block;
  float: right;
  margin-top: -10px;
}
.p-tablebuttonset {
  display: block;
  float: right;
  margin-top: -8px;
}
.p-button-rounded {
  margin-left: 5px;
}
.p-column-filter {
  width: 100%;
}
.div-calender {
  width: 150px;
}

.div-name {
  width: 80%;
}

.div-remark {
  width: 600px;
}
.button-Right {
  align-self: right !important ;
}
.columnRight {
  text-align: right !important ;
}
.columnCenter {
  text-align: center !important ;
}
.columnLeft {
  text-align: center !important ;
}

.layout-topbar-title {
  text-align: center;
  font-size: 24px;
  margin-left: 10px;
}

.layout-login-logo-company {
  text-align: center;
  padding-bottom: 100px;
}

.layout-login-logo {
  text-align: center;
  img {
    width: 100px;
    margin: 10px;
  }
}
// .layout-footer {
//     position: absolute;
//     bottom: 10px;
// }

.layout-profile {
  text-align: center;
  padding: 20px 0;

  img {
    width: 56px;
    margin: 10px;
  }

  .layout-profile-link {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0.75em;
    @include transition(color $transitionDuration);

    i {
      display: inline-block;
      font-size: 16px;
      vertical-align: middle;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    @include transition-property(max-height);
    @include transition-duration(0.4s);
    @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

    &.layout-profile-expanded {
      max-height: 500px;
    }

    li {
      button {
        width: 100%;
        padding: 1em;
        border: 0 none;
        border-radius: 0;
        cursor: pointer;
        @include transition(color $transitionDuration);

        &:hover {
          color: $menuitemHoverColor;
        }

        span {
          margin-left: 0.25em;
          vertical-align: middle;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.layout-sidebar-light {
  .layout-profile {
    .layout-profile-link {
      color: $menuitemColor;

      &:hover {
        color: $menuitemHoverColor;
      }
    }

    ul {
      background-color: $menuitemActiveBgColor;

      li {
        button {
          color: $menuitemColor;

          &:hover {
            color: $menuitemHoverColor;
          }
        }
      }
    }
  }
}

.layout-sidebar-dark {
  .layout-profile {
    .layout-profile-link {
      color: $menuitemDarkColor;

      &:hover {
        color: $menuitemDarkHoverColor;
      }
    }

    ul {
      background-color: $menuitemDarkActiveBgColor;

      li {
        button {
          color: $menuitemDarkColor;

          &:hover {
            color: $menuitemDarkHoverColor;
          }
        }
      }
    }
  }
}
