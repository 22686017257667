* {
  box-sizing: border-box;
}

html {
  height: 80%;
}

body {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: $fontSize;
  color: $textColor;
  background-color: $bodyBgColor;
  margin: 0;
  padding: 0;
  min-height: 00%;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;
  min-height: 100vh;
}

.div-NoOverflow {
  overflow: hidden;
}
