//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

// $sm: 640px;
// $md: 720px;
// $lg: 960px;
// $xl: 1080px;

$sm: 360px;
$md: 720px;
$lg: 960px;
$xl: 1080px;

// $fontSize: 12px;
$textColor: red;

// .p-component {
//   font-size: $fontSize;
// }


.p-disabled, .p-component:disabled {
    opacity: 0.9;
    font-style: italic;
}
