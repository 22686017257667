.cw_wrapText {
  white-space: break-spaces;
}

.ui-tooltip {
  pointer-events: none;
}

// .p-button.p-button-warning.p-button-text {
//   pointer-events: none;
// }